@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@font-face {
	font-family: "Slick";
	src: url("../fonts/ShareTechMono-Regular.ttf");
}

.contentCardWrapper .flexDiv {
	width: 100%;

	display: flex;
	justify-content: space-between;
}

.contentCardWrapper .descDiv {
	width: 100%;
}
.contentCardWrapper .descDiv .header {
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	border-bottom: 2px solid black;
}
.contentCardWrapper .descDiv .header h2,h4 {
	margin-bottom: 0px;
	margin-left: 0.2rem;
	margin-right: 1rem;
}
.contentCardWrapper .descDiv .subHeader h4 {
	text-align: left;

	margin-top: 2px;
	margin-left: 0.2rem;
	margin-right: 1rem;
}
.contentCardWrapper .descDiv .description {
	text-align: left;

	margin-left: 0.1rem;
	margin-right: 0.1rem;
}
.contentCardWrapper .descDiv .description p {
	margin-top: 0px;
	margin-bottom: 5px;
}
.contentCardWrapper .descDiv .flexDiv {
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.contentCardWrapper .projectText {
	font-size: 1.5rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

ul {
  margin-top: 0rem;
  margin-bottom: 0.5rem
}