@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@font-face {
	font-family: "Slick";
	src: url("../fonts/ShareTechMono-Regular.ttf");
}

.body {
	flex-basis: 40%;
}

.masterContainer {
	margin-left: 2%;
	margin-right: 2%;

	transition: transform 1s cubic-bezier(0.34, 1.56, 0.64, 1);
	margin-bottom: 1rem;
}
.masterContainer .text {
	text-align: center;

	color: white;
	font-family: "Bebas Neue";
	margin-top: 1%;
}
.masterContainer:hover {
	transform: scale(1.02, 1.02) !important;
}

.graphContainer {
	background-color: rgba(180, 180, 180, 0.75);	
	border-radius: 1.5rem;
	font-family: "Slick";
	padding: 1%;
	margin-top: 0.5%;

	display: flex;
	flex-direction: column;
	justify-content: center;
}
.graphContainer .graphText {
	font-family: "Slick";
}

/* TODO: Move these out of this CSS file and into its own CSS */
.masterContainerCert {
	margin-left: 2%;
	margin-right: 2%;

	margin-bottom: 1rem;
}
.masterContainerCert .text {
	text-align: center;
	color: white;
	font-family: "Bebas Neue";
	margin-top: 1%;
}

.graphContainerCert {
	font-family: "Slick";
	padding: 1%;
	margin-top: 0.5%;

	display: flex;
	flex-direction: column;
	justify-content: center;
}

.certFlexDiv {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.certFlexDiv a {
  width: fit-content;
}

.certBadge {
  width: 150px;
  height: 150px;
  /* Set some Image Margins */
  padding: 1px;

  /* Animation Settings */
  transition: transform 2s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.certBadge:hover {
  transform: scale(1.02, 1.02) !important;
}

/* Typical flex div 'helper' styling */
.flexDiv {
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.flexDiv .projectText {
	font-size: 1.5rem;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 1rem;
	padding-right: 1rem;
}

.horizFlexDiv {
  width: 100%;

	display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.jobDescDiv {
	width: 100%;
}
.jobDescDiv .header {
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	border-bottom: 2px solid black;
}
.jobDescDiv .header h2,h4 {
	margin-bottom: 0px;
	margin-right: 1rem;
  margin-top: 5px;
}
.jobDescDiv .subHeader {
  display: flex;
}
.jobDescDiv .subHeader h4 {
	text-align: left;

	margin-top: 2px;
  margin-right: 5px;
  margin-left: 0px;
}
.jobDescDiv .subHeader p {
  text-align: left;

  font-size: 95%;

	margin-top: 2px;
	margin-right: 1rem;
}
.jobDescDiv .description {
	text-align: left;

	margin-right: 0.1rem;
}
.jobDescDiv .description p {
	margin-top: -10px;
	margin-bottom: 12px;
}
.jobDescDiv .flexDiv {
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.projectText {
	font-size: 1.5rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

/*
 * =====================================================================================================================
 * Styling for mobile (width == or below 800px) below!
 */
@media only screen and (max-width: 800px) {
	.masterContainer {
		transition: none;
	}
	.masterContainer .text {
		text-shadow:
			-1px -1px 0 #000,  
			1px -1px 0 #000,
			-1px 1px 0 #000,
			1px 1px 0 #000;
	}
	.masterContainer:hover {
		transform: none !important;
	}

	.flexDiv {
		width: 100%;
	
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.flexDiv .projectText {
		padding-left: 0px;
		padding-right: 0px;

		display: flex;
		flex-direction: column;
		align-items: center;
	}
}