@font-face {
    font-family: "base text";
    src: url("../fonts/ShareTechMono-Regular.ttf");
}

@font-face {
    font-family: "BRFont";
    src: url("../fonts/BLADRMF_.TTF");
}

@font-face {
    font-family: "Slick";
    src: url("../fonts/ShareTechMono-Regular.ttf");
}

@keyframes upDown {
    to { transform: translateY(-4vh);}
}

.application {
    scroll-behavior: smooth;
}

body {
    margin: 0;

    background-image: url("../images/backgroundMain.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
}

.content {
    height: 100%;
		width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
		justify-content: center;
}

.staticHeader {
    text-align: center;
    color: white;
}
.staticHeader h1 {
    font-family: "BRFont";
    font-size: 600%;
    margin-top: -1.1rem;
    margin-bottom: -1rem;
}
.staticHeader .over {
    font-family: "base text";
    text-align: left;
    font-size: 150%;
    margin-left: 1rem;
}
.staticHeader p {
    font-family: "base text";
    font-style: italic;
    font-size: 125%;
    margin: 0px;
}

.landing {
	margin-top: 50px;
	min-height: 25%;
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pageBlock {
	width: 100%;
	margin-left: 3%;
	margin-right: 3%;

  display: flex;
  flex-direction: row;
	justify-content: space-evenly;
}
.pageBlock .vertFlexDiv {
  width: 100%;

	display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/*
 * =====================================================================================================================
 * Styling for mobile (width == or below 500px) below!
 */
@media (max-width: 800px) {
    body {
			margin: 0;

			background-image: url("../images/backgroundMobile.jpg");
			background-repeat: no-repeat;
			background-size: cover;
			background-attachment: fixed;
			background-position: center;
	
			width: 100%;
			min-height: 100vh;
    }
    
    .content {
			min-height: 100vh;
			width: 100vw;
	
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
    }
    
    .staticHeader {
			text-align: center;
			color: rgb(255, 255, 255);
			text-shadow:
					-1px -1px 0 #000,  
					1px -1px 0 #000,
					-1px 1px 0 #000,
					1px 1px 0 #000;
    }
    .staticHeader h1 {
			font-family: "BRFont";
			font-size: 3rem;
			margin-top: -0.5rem;
			margin-bottom: -0.5rem;
			text-align: center;
    }
    .staticHeader .over {
			font-family: "base text";
			text-align: center;
			font-size: 1rem;
			margin-left: 1rem;
    }
    .staticHeader p {
			font-family: "base text";
			text-align: center;
			font-style: italic;
			font-size: 1rem;
			margin: 0px;
		}
		.pageBlock {
			width: 100%;
			margin: 0px;
		
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: space-evenly;
		}
}
